import { useEffect, useState } from "react";
import classnames from "classnames";
import Chart from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BASE_URL } from "Api/Api";
import { APP_ID } from "Api/Api";
import { APP_KEY } from "Api/Api";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { chartExample1 } from "variables/charts";
import { greenColor } from "variables/Colors";


const Index = (props) => {
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");
  const [countDatas,setCountDatas] = useState({})

  const history = useHistory()
  const user_id = localStorage.getItem("user_id")

  const [requestsChart, setrequestsChart] = useState([])
  const [requestsLables, setrequestsLables] = useState([])

  if (!user_id) {
    history.push("/")
  }

  const getApiData = () =>{
    const url = `${BASE_URL}count-datas?app_id=${APP_ID}&key=${APP_KEY}`
    fetch(url)
    .then(res=>res.json())
    .then(data=>{
      setCountDatas(data)
      setrequestsChart(data.request_datas.data)
      setrequestsLables(data.request_datas.labels)
    })
  }

  useEffect(()=>{
    getApiData()
  },[])


  let chartOne = {
    data1: (canvas) => {
      return {
        labels: requestsLables,
        datasets: [
          {
            label: "STATISTICS",
            data: requestsChart,
            backgroundColor: greenColor,
            borderColor: greenColor
          },
        ]
      };
    }
  };

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };

  return (
    <>
    <Header />
    <Container className="mt--7" fluid>
      <Row>
        <Col className="mb-5 mb-xl-0" xl="8">
          <Card className="shadow">
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <div className="col">
                  <h6 className="text-uppercase text-light ls-1 mb-1">
                    Overview
                  </h6>
                  <h2 className="mb-0">Driver Requests Statistics</h2>
                </div>
                <div className="col">
                  <Nav className="justify-content-end" pills>
                    <NavItem>
                      <NavLink
                        className={classnames("py-2 px-3 bg-success", {
                          active: activeNav === 1
                        })}
                        href="#pablo"
                        onClick={(e) => toggleNavs(e, 1)}
                      >
                        <span className="d-none d-md-block">Statistics</span>
                        <span className="d-md-none">M</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              {/* Chart */}
              <div className="chart">
                <Bar
                  data={chartOne[chartExample1Data]}
                  options={chartExample1.options}
                  getDatasetAtEvent={(e) => console.log(e)}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl="4">
          <Card className="shadow">
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <div className="col">
                  {/* <h6 className="text-uppercase text-muted ls-1 mb-1">
                    Status
                  </h6> */}
                  <h2 className="mb-0">Users per Role</h2>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              {/* Chart */}
              <div className="chart">
                <Bar
                  data= {{
                    labels: ["DRIVERS", "CUSTOMERS"],
                    datasets: [
                      {
                        label: "Users",
                        data: [
                          countDatas.drivers, countDatas.customers,
                        ],
                        backgroundColor: greenColor,
                        borderColor: greenColor,
                        maxBarThickness: 20,
                      },
                    ]
                  }}
                  options={chartExample1.options}
                  getDatasetAtEvent={(e) => console.log(e)}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* <Row className="mt-5">
        <Col className="mb-5 mb-xl-0" xl="12">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">Courses</h3>
                </div>
                <div className="col text-right">
                  <Button
                    color="primary"
                    // href="#pablo"
                    onClick={(e) => history.push('/admin/courses-list')}
                    size="sm"
                  >
                    See all
                  </Button>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <Table id="coursesTable" className="align-items-center table-flush" responsive>
                <thead className="thead-dark">
                    <tr>
                    <th scope="col">NAME</th>
                    <th scope="col">PERIOD</th>
                    <th scope="col">FEES</th>
                    <th scope="col">REGISTED BY</th>
                    <th scope="col">CREATED AT</th>
                    <th scope="col" />
                    </tr>
                </thead>
                <tbody>
                    {adminCourses.map((data,idx)=>
                    <tr key={idx}>
                        <td>
                            <img alt="..." src={data.photo} style={{width:80, height:70, borderRadius:6}} /> {data.name}
                        </td>
                        <td>{data.period}</td>
                        <td>Tsh: {Intl.NumberFormat().format(data.fees)}</td>
                        <td>{data.registed_by}</td>
                        <td>{data.created_at}</td>
                        <td className="text-right">
                            <UncontrolledDropdown>
                            <DropdownToggle
                                className="btn-icon-only text-dark"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                            >
                                <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                href="#pablo"
                                onClick={(e) => {
                                  e.preventDefault()
                                  deleteCourse(data.id)
                                }}
                                >
                                Delete
                                </DropdownItem>

                                <DropdownItem
                                to={`/update-course/${base64_encode(data.id.toString())}/${base64_encode(data.name)}`}
                                tag={Link}
                                >
                                Update
                                </DropdownItem>
                                <DropdownItem
                                to={`/course-content/${base64_encode(data.id.toString())}/${base64_encode(data.name)}`}
                                tag={Link}
                                >
                                Content
                                </DropdownItem>
                            </DropdownMenu>
                            </UncontrolledDropdown>
                        </td>
                    </tr>
                    )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row> */}
    </Container>
    </>
  );
};

export default Index;
