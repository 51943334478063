import { APP_ID } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { BASE_URL } from "Api/Api";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { greenColor } from "variables/Colors";

const Header = () => {
  const [countDatas,setCountDatas] = useState({})
  useEffect(()=>{
    fetch(`${BASE_URL}count-datas?app_id=${APP_ID}&key=${APP_KEY}`)
    .then(res=>res.json())
    .then(data=>{
      setCountDatas(data)
    })
  },[])

  const role = localStorage.getItem('role')

  return (
    <>
      <div className="header pb-8 pt-5 pt-md-8" style={{backgroundColor:greenColor[0]}}>
        {role==="admin" &&(
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <Link to={`/admin/categories-list`}>
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            REGISTED CATEGORIES
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">{countDatas.categories}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-chart-pie" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-danger mr-2">
                          <i className="fas fa-chart-pie" />
                        </span>{" "}
                        <span className="text-nowrap">Total Categories</span>
                      </p>
                    </CardBody>
                  </Link>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <Link to={`/admin/all-users`}>
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            TOTAL USERS
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">{countDatas.users}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-warning mr-2">
                          <i className="fas fa-users" />
                        </span>{" "}
                        <span className="text-nowrap">Total Users</span>
                      </p>
                    </CardBody>
                  </Link>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <Link to={`/admin/all-drivers`}>
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            TOTAL DRIVERS
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">{countDatas.drivers}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fas fa-users" /> 
                        </span>{" "}
                        <span className="text-nowrap">Total Drivers</span>
                      </p>
                    </CardBody>
                  </Link>
                </Card>
              </Col>

              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <Link to={`/admin/approved-requests`}>
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                            >
                            DRIVERS REQUESTS
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {countDatas.drivers_requests}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-clock" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-clock" />
                        </span>{" "}
                        <span className="text-nowrap">Drivers Requests</span>
                      </p>
                    </CardBody>
                  </Link>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
        )}
      </div>
    </>
  );
};

export default Header;
