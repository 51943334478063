import {
  Card,
  Table,
  Row,
  CardBody} from "reactstrap";
import PlainHeader from "components/Headers/PlainHeader";
import { useEffect, useState } from "react";
import { APP_ID } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { BASE_URL } from "Api/Api";
import Indicator from "components/Loader/Indicator";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import UserProfileLink from "components/Footers/UserProfileLink";

const RejectedRequests = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const user_id = localStorage.getItem("user_id")
  const role = localStorage.getItem("role")

  if (!user_id || role!=="admin") {
      history.push("/")
  }

  const getApiData = () => {
    const url = `${BASE_URL}get_driver_request_rejected_requests?app_id=${APP_ID}&key=${APP_KEY}`
    fetch(url)
    .then(res=>res.json())
    .then(data=>{
        setLoading(false)
        setUsers(data.data)
        $(document).ready(function () {
            $('#myTable').DataTable();
        });
    })
  }

  useEffect(()=>{
      setLoading(true)
      getApiData();
  },[])


  return (
      <>{loading===true ? (<Indicator/>):(
      <>
      {/* Page content */}
      <PlainHeader />
      <CardBody>
        {/* Table */}
          <Row>
              <div className="col">
                  <Card className="shadow">
                      {/* <CardHeader className="border-0">
                          <h3 className="mb-0">Courses list</h3>
                      </CardHeader> */}
                      <CardBody>
                          <Table id="myTable" className="align-items-center table-flush" responsive>
                              <thead className="thead-dark">
                                  <tr>
                                      <th scope="col">S/N</th>
                                      <th scope="col">CUSTOMER DETAILS</th>
                                      <th scope="col">DRIVER DETAILS</th>
                                      <th scope="col">DISTANCE</th>
                                      <th scope="col">AMOUNT</th>
                                      <th scope="col">PICK</th>
                                      <th scope="col">DROP</th>
                                      <th scope="col">CREATED AT</th>
                                      {/* <th scope="col" /> */}
                                  </tr>
                              </thead>
                              <tbody>
                                {users.map((data,idx)=>
                                <tr key={idx}>
                                  <td>{++idx}</td>
                                  <td>
                                    <UserProfileLink user_id={data.customer_id} user_name={data.customer_name} user_photo={data.customer_photo} user_phone={data.customer_phone} user_role={data.customer_role} />
                                  </td>
                                  <td>
                                    {data.driver_name!=="user"&&(
                                      <UserProfileLink user_id={data.driver_id} user_name={data.driver_name} user_photo={data.driver_photo} user_phone={data.driver_phone} user_role={data.driver_role} />
                                    )}
                                  </td>
                                  <td>{data.distance < 1 ? `${Math.round(data.distance)} M` : `${Math.round(data.distance)} KM`}</td>
                                  <td>Tsh: {Intl.NumberFormat().format(data.amount)}</td>

                                  <td>{data.pick_station}</td>
                                  <td>{data.drop_station}</td>

                                  <td>{data.created_at}</td>
                                </tr>
                                )}
                              </tbody>
                          </Table>
                      </CardBody>
                  </Card>
              </div>
          </Row>
      </CardBody>
      </>
      )}</>
  );
};

export default RejectedRequests;