import PlainHeader from "components/Headers/PlainHeader";
import { useState } from "react";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Row } from "reactstrap";
import {encode as base64_encode} from 'base-64';
import { BASE_URL } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { APP_ID } from "Api/Api";
import swal from "sweetalert";
import Loader from "components/Loader/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function RegisterAdmin() {
    const [first_name,set_first_name] = useState("")
    const [last_name,set_last_name] = useState("")
    const [phone_number, set_phone_number] = useState("+255")
    const [email,set_email] = useState("")
    const [password,set_password] = useState("")
    const admin_id = localStorage.getItem('user_id')
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const user_id = localStorage.getItem("user_id")
    const role = localStorage.getItem("role")

    if (!user_id || role!=="admin") {
        history.push("/")
    }

    const registerAdmin = (event) =>{
        event.preventDefault()
        setLoading(true)
        const formData = new FormData();
        
        formData.append("first_name", base64_encode(first_name))
        formData.append("last_name", base64_encode(last_name))
        formData.append("phone_number", base64_encode(phone_number))
        formData.append("email", base64_encode(email))
        formData.append("password", base64_encode(password))
        formData.append("role", base64_encode("admin"))
        formData.append("updated_by", base64_encode(admin_id))
        formData.append("registed_by", base64_encode(admin_id))
        fetch(`${BASE_URL}user-registration?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
        .then(res=>res.json())
        .then(data=>{
            if (data.result==="SUCCESS") {
                setLoading(false)
                event.target.reset()
                swal({
                    title: data.result,
                    text: data.message,
                    icon: "success",
                    button: "Okay",
                });
            } else {
                setLoading(false)
                swal({
                    title: data.result,
                    text: data.message,
                    icon: "warning",
                    button: "Okay",
                });
            }
        })
        .catch((err)=>{
            setLoading(false)
            swal({
                title: "Network Request Error",
                text: err.message,
                icon: "warning",
                button: "Okay",
            });
        })
    }

    return(
        <>
        {localStorage.getItem("super_admin")==="YES" ? (        
            <>
            <PlainHeader />
            <CardBody>
                <Card>
                    <CardBody>
                        <Form autoComplete="off" id="category-form" onSubmit={(event)=>registerAdmin(event)}>
                            <h6 className="heading-small text-muted mb-4">
                            Register Admin
                            </h6>
                            <div className="pl-lg">
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-first-name"
                                                >
                                            First Name
                                            </label>
                                            <Input
                                                className="form-control"
                                                id="input-first-name"
                                                placeholder="First Name"
                                                autoFocus
                                                type="text"
                                                // value={name}
                                                onChange={(e)=>set_first_name(e.target.value)}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-last-name"
                                                >
                                            Last Name
                                            </label>
                                            <Input
                                                className="form-control"
                                                id="input-last-name"
                                                placeholder="Last Name"
                                                autoFocus
                                                type="text"
                                                // value={name}
                                                onChange={(e)=>set_last_name(e.target.value)}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-phone-number"
                                                >
                                            Phone Number
                                            </label>
                                            <Input
                                                className="form-control"
                                                id="input-phone-number"
                                                placeholder="Phone Number"
                                                autoFocus
                                                type="text"
                                                value={phone_number}
                                                onChange={(e)=>set_phone_number(e.target.value)}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-email"
                                                >
                                            Email
                                            </label>
                                            <Input
                                                className="form-control"
                                                id="input-email"
                                                placeholder="Email"
                                                autoFocus
                                                type="text"
                                                // value={name}
                                                onChange={(e)=>set_email(e.target.value)}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>


                                    <Col lg="12">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-password"
                                                >
                                            Password
                                            </label>
                                            <Input
                                                className="form-control"
                                                id="input-password"
                                                placeholder="Password"
                                                autoFocus
                                                type="text"
                                                // value={name}
                                                onChange={(e)=>set_password(e.target.value)}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {loading===true ? (
                                    <Loader/>
                                ):(
                                    <Button color="primary">
                                    Register
                                    </Button>
                                )}
                            </div>
                            <hr className="my-4" />
                        </Form>
                    </CardBody>
                </Card>
            </CardBody>
            </>
        ):(
            <div style={{textAlign:"center", justifyContent:"center", paddingTop:"20%"}}>
                <h1>You have no access to this panel!</h1>
            </div>
        )}
        </>
    )
}