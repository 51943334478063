import {
  Card,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Row,
  CardBody
} from "reactstrap";
import PlainHeader from "components/Headers/PlainHeader";
import { useEffect, useState } from "react";
import { APP_ID } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { BASE_URL } from "Api/Api";
import Indicator from "components/Loader/Indicator";
import {encode as base64_encode} from 'base-64';
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

const Drivers = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const user_id = localStorage.getItem("user_id")
  const role = localStorage.getItem("role")

  if (!user_id || role!=="admin") {
      history.push("/")
  }

  const getApiData = () => {
      fetch(`${BASE_URL}get-all-drivers/${base64_encode(user_id)}?app_id=${APP_ID}&key=${APP_KEY}`)
      .then(res=>res.json())
      .then(data=>{
          setLoading(false)
          setUsers(data.data)
          $(document).ready(function () {
              $('#myTable').DataTable();
          });
      })
  }

  useEffect(()=>{
      setLoading(true)
      getApiData();
  },[])


  return (
      <>{loading===true ? (<Indicator/>):(
      <>
      {/* Page content */}
      <PlainHeader />
      <CardBody>
        {/* Table */}
          <Row>
              <div className="col">
                  <Card className="shadow">
                      {/* <CardHeader className="border-0">
                          <h3 className="mb-0">Courses list</h3>
                      </CardHeader> */}
                      <CardBody>
                          <Table id="myTable" className="align-items-center table-flush" responsive>
                              <thead className="thead-dark">
                                  <tr>
                                      <th scope="col">S/N</th>
                                      <th scope="col">FULL NAME</th>
                                      <th scope="col">PHONE</th>
                                      <th scope="col">EMAIL</th>
                                      <th scope="col">STATUS</th>
                                      <th scope="col">CREATED AT</th>
                                      <th scope="col" />
                                  </tr>
                              </thead>
                              <tbody>
                                  {users.map((data,idx)=>
                                  <tr key={idx}>
                                      <td>{++idx}</td>
                                      <td>
                                          <img src={data.photo} style={{width:60, height:60, borderRadius:"50%"}} alt="..." /> {data.first_name} {data.last_name}
                                      </td>
                                      <td>{data.phone_number}</td>
                                      <td>{data.email}</td>
                                      <td>
                                        {(()=>{
                                          if (data.status==="REQUESTED") {
                                            return(
                                              <span className="btn-danger p-1" style={{borderRadius:6}}><b>{data.status}</b></span>
                                            )
                                          }else if (data.status==="ACTIVE") {
                                            return(
                                              <span className="btn-info p-1" style={{borderRadius:6}}><b>{data.status}</b></span>
                                            )
                                          }else if (data.status==="DISABLED") {
                                            return(
                                              <span className="btn-warning p-1" style={{borderRadius:6}}><b>{data.status}</b></span>
                                            )
                                          }else{
                                            return(
                                              <span className="btn-danger p-1" style={{borderRadius:6}}><b>{data.status}</b></span>
                                            )
                                          }
                                        })()}
                                      </td>
                                      <td>{data.created_at}</td>
                                      <td className="text-right">
                                          <UncontrolledDropdown>
                                          <DropdownToggle
                                              className="btn-icon-only text-dark"
                                              href="#pablo"
                                              role="button"
                                              size="sm"
                                              color=""
                                              onClick={(e) => e.preventDefault()}
                                          >
                                              <i className="fas fa-ellipsis-v" />
                                          </DropdownToggle>
                                          <DropdownMenu className="dropdown-menu-arrow" right>
                                              <DropdownItem
                                              to={`/view-driver-profile/${base64_encode(data.id.toString())}/${base64_encode(`${data.first_name} ${data.last_name}`)}`}
                                              tag={Link}
                                              >
                                              View More
                                              </DropdownItem>
                                          </DropdownMenu>
                                          </UncontrolledDropdown>
                                      </td>
                                  </tr>
                                  )}
                              </tbody>
                          </Table>
                      </CardBody>
                  </Card>
              </div>
          </Row>
      </CardBody>
      </>
      )}</>
  );
};

export default Drivers;