export default function Loader() {
    return(
        <div style={{background:"transparent"}}>
            <img
              style={{width:60, height:60}}
              alt="Loading..."
              src={require("../../assets/img/theme/indicator.gif")}
            />
        </div>
    )
}