export const Colors = [
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
]


export const greenColor = [
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
    "#058A47",
]