import PlainHeader from "components/Headers/PlainHeader";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Row, Table, UncontrolledDropdown } from "reactstrap";
import {encode as base64_encode} from 'base-64';
import { BASE_URL } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { APP_ID } from "Api/Api";
import swal from "sweetalert";
import Loader from "components/Loader/Loader";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Indicator from "components/Loader/Indicator";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';


export default function RegisterRegion() {
    const [name,setName] = useState("")
    const [latitude,setLatitude] = useState("")
    const [longitude,setLongitude] = useState("")
    const admin_id = localStorage.getItem('user_id')
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()
    const user_id = localStorage.getItem("user_id")
    const role = localStorage.getItem("role")
    const [regions, setRegions] = useState([])

    if (!user_id || role!=="admin") {
        history.push("/")
    }

    const getApiData = () =>{
      const url = `${BASE_URL}get-all-regions?app_id=${APP_ID}&key=${APP_KEY}`
      fetch(url)
      .then(res=>res.json())
      .then(data=>{
        setRegions(data)
        $(document).ready(function () {
          $('#myTable').DataTable();
        });
        setIsLoading(false)
      })
    }

    useEffect(()=>{
      setIsLoading(true)
      getApiData()
    },[getApiData()])

    const registerCourse = (event) =>{
        event.preventDefault()
        setLoading(true)
        const formData = new FormData();
        formData.append("name", base64_encode(name))
        formData.append("latitude", base64_encode(latitude))
        formData.append("longitude", base64_encode(longitude))
        formData.append("updated_by", base64_encode(admin_id))
        formData.append("registed_by", base64_encode(admin_id))
        fetch(`${BASE_URL}register-region?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
        .then(res=>res.json())
        .then(data=>{
          getApiData()
          if (data.result==="SUCCESS") {
              setLoading(false)
              event.target.reset()
              swal({
                  title: data.result,
                  text: data.message,
                  icon: "success",
                  button: "Okay",
              });
          } else {
              setLoading(false)
              swal({
                  title: data.result,
                  text: data.message,
                  icon: "warning",
                  button: "Okay",
              });
          }
        })
        .catch((err)=>{
            setLoading(false)
            swal({
                title: "Network Request Error",
                text: err.message,
                icon: "warning",
                button: "Okay",
            });
        })
    }

    function deleteRegion(region_id) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
          .then((willDelete) => {
            if (willDelete) {
                fetch(`${BASE_URL}delete-region/${base64_encode(region_id.toString())}?app_id=${APP_ID}&key=${APP_KEY}`)
                .then(del=>del.json())
                .then(del=>{
                    swal(`${del.result}! ${del.message}!`, {
                        icon: "success",
                    });
                    getApiData()
                })
            } else {
              swal("Your data is safe!");
            }
        });
    }


    return(
        <>{isLoading===true ? (<Indicator/>):(
        <>
        <PlainHeader />
        <CardBody>
            <Card>
                <CardBody>
                    <Form autoComplete="off" id="category-form" onSubmit={(event)=>registerCourse(event)}>
                        <h6 className="heading-small text-muted mb-4">
                        Regions
                        </h6>
                        <div className="pl-lg">
                            <Row>
                                <Col lg="12">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-course-name"
                                            >
                                          Region name
                                        </label>
                                        <Input
                                            className="form-control"
                                            id="input-course-name"
                                            placeholder="Region name"
                                            autoFocus
                                            type="text"
                                            // value={name}
                                            onChange={(e)=>setName(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                </Col>

                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-latitude"
                                            >
                                          Latitude
                                        </label>
                                        <Input
                                            className="form-control"
                                            id="input-latitude"
                                            placeholder="Latitude"
                                            type="text"
                                            // value={name}
                                            onChange={(e)=>setLatitude(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                </Col>

                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-longitude"
                                            >
                                          Longitude
                                        </label>
                                        <Input
                                            className="form-control"
                                            id="input-longitude"
                                            placeholder="Longitude"
                                            type="text"
                                            // value={name}
                                            onChange={(e)=>setLongitude(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                </Col>

                            </Row>

                            {loading===true ? (
                                <Loader/>
                            ):(
                                <Button color="primary">
                                  Register
                                </Button>
                            )}
                        </div>
                    </Form>

                    <hr/>

                    <Table id="myTable" className="align-items-center table-flush" responsive>
                      <thead className="thead-dark">
                          <tr>
                              <th scope="col">S/N</th>
                              <th scope="col">NAME</th>
                              <th scope="col">REGISTED BY</th>
                              <th scope="col">LATITUDE</th>
                              <th scope="col">LONGITUDE</th>
                              <th scope="col">CREATED AT</th>
                              <th scope="col" />
                          </tr>
                      </thead>
                      <tbody>
                          {regions.map((data,idx)=>
                          <tr key={idx}>
                            <td>{++idx}</td>
                            <td>{data.name}</td>
                            <td>{data.registed_by}</td>
                            <td>{data.latitude}</td>
                            <td>{data.longitude}</td>
                            <td>{data.created_at}</td>
                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                    className="btn-icon-only text-dark"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                    <DropdownItem
                                    href="#pablo"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        deleteRegion(data.id)
                                    }}
                                    >
                                    Delete
                                    </DropdownItem>

                                    <DropdownItem
                                    to={`/update-region/${base64_encode(data.id.toString())}/${base64_encode(data.name)}`}
                                    tag={Link}
                                    >
                                    Update
                                    </DropdownItem>

                                    <DropdownItem
                                    to={`/register-region-districts/${base64_encode(data.id.toString())}/${base64_encode(data.name)}`}
                                    tag={Link}
                                    >
                                    Districts
                                    </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                          )}
                      </tbody>
                  </Table>
                </CardBody>
            </Card>
        </CardBody>
        </>
        )}</>
    )
}