import { encode as base64_encode } from 'base-64';

// export const BASE_URL = `http://127.0.0.1:5000/api-gateway/u/0/v1/`
export const BASE_URL = `https://apigtw.taifamoja.co.tz/api-gateway/u/0/v1/`
export const LEOLEO_URL = `https://management.leoleo.africa/leoleo-guliosmart-gtw/v1/`;
export const SELCOM_URL = `https://leoleotoken.sandc.co.tz/sandc_payment/`

const app_id = `23548020`;
const app_key = `SandC-Technology-c8435fdf6c4dbf2297089fc57c00bdf694c37c2632175d0bacbc7b00a63987bef149b99deef2b8f59d170c454d3ed3d1`;
export const APP_ID = base64_encode(app_id);
export const APP_KEY = base64_encode(app_key);