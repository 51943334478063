import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { useEffect, useState } from "react";
import { BASE_URL } from "Api/Api";
import { APP_ID } from "Api/Api";
import { APP_KEY } from "Api/Api";
import {encode as base64_encode} from 'base-64';
import Loader from "components/Loader/Loader";
import Indicator from "components/Loader/Indicator";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import swal from "sweetalert";


const Profile = () => {
  const [user,setuserProfile] = useState({})
  const user_id = localStorage.getItem("user_id")
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [oldPassword, setoldPassword] = useState("")
  const [passwordOne, setpasswordOne] = useState("")
  const [passwordTwo, setpasswordTwo] = useState("")

  const history = useHistory()
  
  if (!user_id) {
    history.push("/")
  }

  const getApiData = () => {
    fetch(`${BASE_URL}get-user-profile/${base64_encode(user_id)}?app_id=${APP_ID}&key=${APP_KEY}`)
    .then(res=>res.json())
    .then(data=>{
      setLoading(false)
      setuserProfile(data)
    })
  }

  useEffect(()=>{
    setLoading(true)
    getApiData()
  },[user_id])


  function changeAdminPasswordFunction(event) {
    event.preventDefault()
    if (passwordOne!==passwordTwo) {
      swal({
        title: "Password does not Match",
        text: "Please comfirm your password correctly!",
        icon: "warning",
        button: "Okay",
      });
    } else {
      setIsLoading(true)
      const formData = new FormData()
      formData.append("user_id", base64_encode(user_id))
      formData.append("passwordOne", base64_encode(oldPassword))
      formData.append("passwordTwo", base64_encode(passwordTwo))
      fetch(`${BASE_URL}update-admin-password?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
      .then(res=>res.json())
      .then(data=>{
        setIsLoading(false)
        swal({
          title: data.result,
          text: data.message,
          icon: "warning",
          button: "Okay",
        });
      })
      .catch((err)=>{
        setIsLoading(false)
        swal({
          title: "Network Request Error",
          text: err.message,
          icon: "warning",
          button: "Okay",
        });
      })
    }
  }


  return (
    <>{loading===true ? (<Indicator/>) :(
    <>
      <UserHeader full_name={localStorage.getItem('username')} />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="rounded-circle"
                        src={`${localStorage.getItem("user_image")}`}
                        // src={require("../../assets/img/icons/common/user.png")}
                      />
                    </a>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                {/* <div className="d-flex justify-content-between">
                  <Button
                    className="mr-4"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    Connect
                  </Button>
                  <Button
                    className="float-right"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    Message
                  </Button>
                </div> */}
              </CardHeader>
              <CardBody className="pt-0 pt-md-4">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                      {/* <div>
                        <span className="heading">22</span>
                        <span className="description">Friends</span>
                      </div>
                      <div>
                        <span className="heading">10</span>
                        <span className="description">Photos</span>
                      </div>
                      <div>
                        <span className="heading">89</span>
                        <span className="description">Comments</span>
                      </div> */}
                    </div>
                  </div>
                </Row>
                <div className="text-center">
                  <h3>
                    {user.username}
                    {/* <span className="font-weight-light">, 27</span> */}
                  </h3>
                  <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    Mbeya - Tanzania
                  </div>
                  <div className="h5 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    Admin
                  </div>
                  <div>
                    <i className="ni education_hat mr-2" />
                    SandC Technology Company Limited
                  </div>
                  <hr className="my-4" />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                  {localStorage.getItem("username")==="sandc_technology"&&(
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      // href="#pablo"
                      onClick={(e) => {
                        e.preventDefault()
                        history.push(`/system-admins-list`)
                      }}
                    >
                      System Admins
                    </Button>
                  </Col>
                  )}
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Username
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={user.username}
                            id="input-username"
                            placeholder="Username"
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="jesse@example.com"
                            defaultValue={user.email}
                            type="email"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            First name
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={user.first_name}
                            id="input-first-name"
                            placeholder="First name"
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Last name
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={user.last_name}
                            id="input-last-name"
                            placeholder="Last name"
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
                <hr className="my-4" />

                <Form onSubmit={(e)=>changeAdminPasswordFunction(e)}>
                  <h6 className="heading-small text-muted mb-4">
                    Change Password
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-password"
                          >
                            Old Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            onChange={(e)=>setoldPassword(e.target.value)}
                            defaultValue={oldPassword}
                            id="input-password"
                            placeholder="Old Password"
                            type="password"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-new-password"
                          >
                            New Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-new-password"
                            placeholder="New Password"
                            onChange={(e)=>setpasswordOne(e.target.value)}
                            defaultValue={passwordOne}
                            type="password"
                            required
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-new-password-confirm"
                          >
                            Confirm New Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-new-password-confirm"
                            placeholder="Confirm New Password"
                            onChange={(e)=>setpasswordTwo(e.target.value)}
                            defaultValue={passwordTwo}
                            type="password"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        {isLoading===true ? (<Loader/>):(
                        <Button
                          color="primary"
                          // href="#pablo"
                          >
                          Change Password
                        </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
    )}</>
  );
};

export default Profile;
