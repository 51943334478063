import {
  Card,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Row,
  CardBody
} from "reactstrap";
import PlainHeader from "components/Headers/PlainHeader";
import { useEffect, useState } from "react";
import { APP_ID } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { BASE_URL } from "Api/Api";
import Indicator from "components/Loader/Indicator";
import { encode as base64_encode } from 'base-64';
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

const SubscriptionHistory = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const user_id = localStorage.getItem("user_id")
  const role = localStorage.getItem("role")

  if (!user_id || role !== "admin") {
    history.push("/")
  }

  const getApiData = () => {
    fetch(`${BASE_URL}get_subscription_history?app_id=${APP_ID}&key=${APP_KEY}`)
      .then(res => res.json())
      .then(data => {
        setLoading(false)
        setUsers(data)
        $(document).ready(function () {
          $('#myTable').DataTable();
        });
      })
  }

  useEffect(() => {
    setLoading(true)
    getApiData();
  }, [])


  return (
    <>{loading === true ? (<Indicator />) : (
      <>
        {/* Page content */}
        <PlainHeader />
        <CardBody>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                {/* <CardHeader className="border-0">
                          <h3 className="mb-0">Courses list</h3>
                      </CardHeader> */}
                <CardBody>
                  <Table id="myTable" className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">S/N</th>
                        <th scope="col">FULL NAME</th>
                        <th scope="col">PHONE</th>
                        <th scope="col">EMAIL</th>
                        <th scope="col">STARTING DATE</th>
                        <th scope="col">EXPIRE DATE</th>
                        <th scope="col">TRANSACTION ID</th>
                        <th scope="col">DAYS</th>
                        <th scope="col">STATUS</th>
                        <th scope="col">CREATED AT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((data, idx) =>
                        <tr key={idx}>
                          <td>{++idx}</td>
                          <td>
                            <img src={data.photo} style={{ width: 60, height: 60, borderRadius: "50%" }} alt="..." /> {data.full_name}
                          </td>
                          <td>{data.phone_number}</td>
                          <td>{data.email}</td>
                          <td>{data.starting_date}</td>
                          <td>{data.expiry_date}</td>
                          <td>{data.transaction_id}</td>
                          <td>{data.valid_days}</td>
                          <td>
                            {(() => {
                              if (data.active_status === "PENDING") {
                                return (
                                  <span className="btn-danger p-1" style={{ borderRadius: 6 }}><b>{data.active_status}</b></span>
                                )
                              } else if (data.active_status === "ACTIVE") {
                                return (
                                  <span className="btn-info p-1" style={{ borderRadius: 6 }}><b>{data.active_status}</b></span>
                                )
                              } else if (data.active_status === "EXPIRED") {
                                return (
                                  <span className="btn-warning p-1" style={{ borderRadius: 6 }}><b>{data.active_status}</b></span>
                                )
                              } else {
                                return (
                                  <span className="btn-danger p-1" style={{ borderRadius: 6 }}><b>{data.active_status}</b></span>
                                )
                              }
                            })()}
                          </td>
                          <td>{data.created_at}</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </Row>
        </CardBody>
      </>
    )}</>
  );
};

export default SubscriptionHistory;