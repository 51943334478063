export function OneStar() {
  return (
    <div>
      <i className="fa fa-star"></i>
    </div>
  )
}

export function TwoStar() {
  return (
    <div>
      <i className="fa fa-star"></i>
      <i className="fa fa-star"></i>
    </div>
  )
}

export function ThreeStar() {
  return (
    <div>
      <i className="fa fa-star"></i>
      <i className="fa fa-star"></i>
      <i className="fa fa-star"></i>
    </div>
  )
}

export function FourStar() {
  return (
    <div>
      <i className="fa fa-star"></i>
      <i className="fa fa-star"></i>
      <i className="fa fa-star"></i>
      <i className="fa fa-star"></i>
    </div>
  )
}

export function FiveStar() {
  return (
    <div>
      <i className="fa fa-star"></i>
      <i className="fa fa-star"></i>
      <i className="fa fa-star"></i>
      <i className="fa fa-star"></i>
      <i className="fa fa-star"></i>
    </div>
  )
}