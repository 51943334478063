import PlainHeader from "components/Headers/PlainHeader";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Row, Table, UncontrolledDropdown } from "reactstrap";
import {encode as base64_encode, decode as base64_decode} from 'base-64';
import { BASE_URL } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { APP_ID } from "Api/Api";
import swal from "sweetalert";
import Loader from "components/Loader/Loader";
import React from "react";
import { useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import { Link, useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Indicator from "components/Loader/Indicator";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';


const Districts = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [name,setName] = useState("")
  const [latitude,setLatitude] = useState("")
  const [longitude,setLongitude] = useState("")
  const admin_id = localStorage.getItem('user_id')
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const user_id = localStorage.getItem("user_id")
  const role = localStorage.getItem("role")
  const [photo, setPhoto] = useState("")
  const [districts, setDistricts] = useState([])

  if (!user_id || role!=="admin") {
    history.push("/")
  }

  document.addEventListener("DOMContentLoaded", function () {
    history.push(location.pathname)
  })
  
  const params = useParams()

  const getApiData = () => {
    fetch(`${BASE_URL}get-region-districts/${params.region_id}?app_id=${APP_ID}&key=${APP_KEY}`)
    .then(res=>res.json())
    .then(data=>{
      setDistricts(data)
      $(document).ready(function () {
        $('#myTable').DataTable();
      });
      setIsLoading(false)
    })
  }

  useEffect(()=>{
    setIsLoading(true)
    getApiData()
  },[params.region_id])

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);


  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return base64_decode(params.region_name);
  };

  const registerDistrict = (event) =>{
    event.preventDefault()
    setLoading(true)
    const formData = new FormData();
    formData.append("region_id", params.region_id)
    formData.append("name", base64_encode(name))
    formData.append("latitude", base64_encode(latitude))
    formData.append("longitude", base64_encode(longitude))
    formData.append("updated_by", base64_encode(admin_id))
    formData.append("registed_by", base64_encode(admin_id))
    fetch(`${BASE_URL}register-district?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
    .then(res=>res.json())
    .then(data=>{
        if (data.result==="SUCCESS") {
          event.target.reset()
          setLoading(false)
          getApiData()
          swal({
              title: data.result,
              text: data.message,
              icon: "success",
              button: "Okay",
          });
        } else {
            setLoading(false)
            swal({
                title: data.result,
                text: data.message,
                icon: "warning",
                button: "Okay",
            });
        }
    })
    .catch((err)=>{
      setLoading(false)
      swal({
        title: "Network Request Error",
        text: err.message,
        icon: "warning",
        button: "Okay",
      });
    })
  }

  function deleteDistrict(district_id) {
    swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this data!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
            fetch(`${BASE_URL}delete-district/${base64_encode(district_id.toString())}?app_id=${APP_ID}&key=${APP_KEY}`)
            .then(del=>del.json())
            .then(del=>{
                swal(`${del.result}! ${del.message}!`, {
                    icon: "success",
                });
                getApiData()
            })
        } else {
          swal("Your data is safe!");
        }
    });
  }

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../../assets/img/brand/logo.png"),
          imgAlt: "..."
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <>{isLoading===true ? (<Indicator/>) :(
        <>
        <PlainHeader />
        <CardBody>
            <Card>
                <CardBody>
                    <Form autoComplete="off" onSubmit={(event)=>registerDistrict(event)}>
                        <h6 className="heading-small text-muted mb-4">
                        <Button
                            color="primary"
                            // href="#pablo"
                            onClick={() => history.goBack()}
                            size="sm"
                            >
                            <i className="fa fa-angle-double-left"></i> back
                        </Button> {base64_decode(params.region_name)}
                        </h6>
                        <div className="pl-lg">
                            <Row>
                                <Col lg="12">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-course-name"
                                            >
                                          District name
                                        </label>
                                        <Input
                                            className="form-control"
                                            id="input-course-name"
                                            placeholder="District name"
                                            autoFocus
                                            type="text"
                                            // value={name}
                                            onChange={(e)=>setName(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                </Col>

                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-latitude"
                                            >
                                          Latitude
                                        </label>
                                        <Input
                                            className="form-control"
                                            id="input-latitude"
                                            placeholder="Latitude"
                                            type="text"
                                            // value={name}
                                            onChange={(e)=>setLatitude(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                </Col>

                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-longitude"
                                            >
                                          Longitude
                                        </label>
                                        <Input
                                            className="form-control"
                                            id="input-longitude"
                                            placeholder="Longitude"
                                            type="text"
                                            // value={name}
                                            onChange={(e)=>setLongitude(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                </Col>

                            </Row>
                          
                            {loading===true ? (
                                <Loader/>
                            ):(
                                <Button color="primary">
                                  Register
                                </Button>
                            )}
                        </div>
                    </Form>

                    <hr/>

                    <Table id="myTable" className="align-items-center table-flush" responsive>
                      <thead className="thead-dark">
                          <tr>
                              <th scope="col">S/N</th>
                              <th scope="col">NAME</th>
                              <th scope="col">REGISTED BY</th>
                              <th scope="col">LATITUDE</th>
                              <th scope="col">LONGITUDE</th>
                              <th scope="col">CREATED AT</th>
                              <th scope="col" />
                          </tr>
                      </thead>
                      <tbody>
                          {districts.map((data,idx)=>
                          <tr key={idx}>
                            <td>{++idx}</td>
                            <td>{data.name}</td>
                            <td>{data.registed_by}</td>
                            <td>{data.latitude}</td>
                            <td>{data.longitude}</td>
                            <td>{data.created_at}</td>
                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                    className="btn-icon-only text-dark"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                    <DropdownItem
                                    href="#pablo"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      deleteDistrict(data.id)
                                    }}
                                    >
                                    Delete
                                    </DropdownItem>

                                    <DropdownItem
                                    to={`/update-district/${base64_encode(data.id.toString())}/${base64_encode(data.name)}`}
                                    tag={Link}
                                    >
                                    Update
                                    </DropdownItem>

                                    <DropdownItem
                                    to={`/register-district-wards/${base64_encode(data.id.toString())}/${base64_encode(data.name)}`}
                                    tag={Link}
                                    >
                                    Wards & States
                                    </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                          )}
                      </tbody>
                  </Table>
                </CardBody>
            </Card>
        </CardBody>
        </>
        )}</>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Districts;
