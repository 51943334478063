import Index from "views/Index.js";
import RegisterCategory from "views/examples/RegisterCategory";
import Categories from "views/examples/Categories";
import Profile from "views/examples/Profile";
import Users from "views/examples/Users";
import Drivers from "views/examples/Drivers";
import RegisterRegion from "./views/examples/RegisterRegion";
import DriversRequests from "views/examples/DriversRequests";
import PendingRequests from "views/examples/PendingRequests";
import ApprovedRequests from "views/examples/ApprovedRequests";
import RejectedRequests from "views/examples/RejectedRequests";
import DriverActivationFee from "views/examples/DriverActivationFee";
import CompletedRequests from "views/examples/CompletedRequests";
import SubscriptionAccountTypes from "views/examples/SubscriptionAccountTypes";
import RegisterAdmin from "views/examples/RegisterAdmin";
import SubscriptionHistory from "views/examples/SubscriptionHistory";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin"
  },
  // {
  //   path: "/register-categories",
  //   name: "Register Category",
  //   icon: "fa fa-edit text-primary",
  //   component: RegisterCategory,
  //   layout: "/admin"
  // },
  {
    path: "/categories-list",
    name: "Categories",
    icon: "fa fa-list text-success",
    component: Categories,
    layout: "/admin"
  },
  {
    path: "/regions-list-and-registration",
    name: "Locations",
    icon: "fa fa-map-marker-alt text-danger",
    component: RegisterRegion,
    layout: "/admin"
  },
  {
    path: "/all-users",
    name: "All Users",
    icon: "fa fa-users text-info",
    component: Users,
    layout: "/admin"
  },
  {
    path: "/register-admin",
    name: "Register Admin",
    icon: "fa fa-edit text-success",
    component: RegisterAdmin,
    layout: "/admin"
  },
  {
    path: "/all-drivers",
    name: "Drivers",
    icon: "fa fa-car text-warning",
    component: Drivers,
    layout: "/admin"
  },
  {
    path: "/driver-activation-fee",
    name: "Driver Activation Fee",
    icon: "fa fa-circle text-success",
    component: DriverActivationFee,
    layout: "/admin"
  },
  {
    path: "/drivers-subscription-accounts-types",
    name: "Driver Accounts Types",
    icon: "fa fa-handshake text-success",
    component: SubscriptionAccountTypes,
    layout: "/admin"
  },
  {
    path: "/drivers-subscription-history",
    name: "Subscription History",
    icon: "fa fa-history text-info",
    component: SubscriptionHistory,
    layout: "/admin"
  },
  {
    path: "/drivers-verification-requests",
    name: "Drivers Verification Requests",
    icon: "fa fa-clock text-info",
    component: DriversRequests,
    layout: "/admin"
  },
  {
    path: "/pending-requests",
    name: "Pending Requests",
    icon: "fa fa-history text-danger",
    component: PendingRequests,
    layout: "/admin"
  },
  {
    path: "/approved-requests",
    name: "Approved Requests",
    icon: "fa fa-check text-success",
    component: ApprovedRequests,
    layout: "/admin"
  },
  {
    path: "/completed-requests",
    name: "Completed Requests",
    icon: "fa fa-check-circle text-success",
    component: CompletedRequests,
    layout: "/admin"
  },
  {
    path: "/rejected-requests",
    name: "Rejected Requests",
    icon: "fa fa-exclamation-triangle text-success",
    component: RejectedRequests,
    layout: "/admin"
  },
  {
    path: "/user-profile",
    name: "Profile",
    icon: "fa fa-user text-warning",
    component: Profile,
    layout: "/admin"
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin"
  // },
  // {
  //   path: "/login",
  //   name: "Login",
  //   icon: "ni ni-key-25 text-info",
  //   component: Login,
  //   layout: "/auth"
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth"
  // },
];

export default routes;
