import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/font.css";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import UpdateCategory from "views/examples/UpdateCategory";
import Districts from "views/examples/Districts";
import Wards from "views/examples/Wards";
import UpdateRegion from "views/examples/UpdateRegion";
import UpdateDistrict from "views/examples/UpdateDistrict";
import UpdateWard from "views/examples/UpdateWard";
import ViewDriverProfile from "views/examples/ViewDriverProfile";
import ViewUserProfile from "views/examples/ViewUserProfile";

const root = ReactDOM.createRoot(document.getElementById("root"));

const user_id = localStorage.getItem("user_id")

root.render(
  <BrowserRouter>
    <Switch>
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />

      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />

      <Route path="/update-category/:category_id/:category_name" render={(props)=><UpdateCategory {...props} />} />
      
      <Route path="/register-region-districts/:region_id/:region_name" render={(props)=><Districts {...props} />} />

      <Route path="/register-district-wards/:district_id/:district_name" render={(props)=><Wards {...props} />} />

      <Route path="/update-region/:region_id/:region_name" render={(props)=><UpdateRegion {...props} />} />

      <Route path="/update-district/:district_id/:district_name" render={(props)=><UpdateDistrict {...props} />} />

      <Route path="/update-ward/:ward_id/:ward_name" render={(props)=><UpdateWard {...props} />} />

      <Route path="/view-driver-profile/:driver_id/:driver_name" render={(props)=><ViewDriverProfile {...props} />} />

      <Route path="/view-user-profile/:user_id/:user_name" render={(props)=><ViewUserProfile {...props} />} />

      {user_id ? <Redirect from="/" to="/admin/index" /> : <Redirect from="/" to="/auth/login" />}
    </Switch>
  </BrowserRouter>
);
