import { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Row } from "reactstrap";
import {encode as base64_encode, decode as base64_decode} from 'base-64';
import { BASE_URL } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { APP_ID } from "Api/Api";
import swal from "sweetalert";
import React from "react";
import { useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Indicator from "components/Loader/Indicator";
import UserHeader from "components/Headers/UserHeader";

const ViewDriverProfile = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const user_id = localStorage.getItem("user_id")
  const role = localStorage.getItem("role")
  const [data, setData] = useState(null)

  if (!user_id || role!=="admin") {
    history.push("/")
  }

  document.addEventListener("DOMContentLoaded", function () {
    history.push(location.pathname)
  })
  
  const params = useParams()

  const getApiData = () => {
    const url =`${BASE_URL}get-driver-profile/${params.driver_id}?app_id=${APP_ID}&key=${APP_KEY}`
    fetch(url)
    .then(res=>res.json())
    .then(data=>{
      setIsLoading(false)
      setData(data)
    })
  }
  

  useEffect(()=>{
    setIsLoading(true)
    getApiData()
  },[params.driver_id])

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);


  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return base64_decode(params.driver_name);
  };

  const activateDriverAccount = () =>{
    swal({
      title: "Are you sure!",
      text: "You want to activate driver status?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willUpdate) => {
        if (willUpdate) {
          const formData = new FormData()
          formData.append("driver_id", params.driver_id)
          formData.append("status", base64_encode("ACTIVE"))
          fetch(`${BASE_URL}update_driver_status?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
          .then(res=>res.json())
          .then(res=>{
            getApiData()
            swal(`${res.result}! ${res.message}!`, {
              icon: "success",
            });
          })
          .catch(err=>{
            swal(err.message)
          })
        } else {
          swal("Account activation canceled!");
        }
    })
  }


  const deactivateDriverAccount = () =>{
    swal({
      title: "Are you sure!",
      text: "You want to disable driver status?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willUpdate) => {
        if (willUpdate) {
          const formData = new FormData()
          formData.append("driver_id", params.driver_id)
          formData.append("status", base64_encode("DISABLED"))
          fetch(`${BASE_URL}update_driver_status?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
          .then(res=>res.json())
          .then(res=>{
            getApiData()
            swal(`${res.result}! ${res.message}!`, {
              icon: "success",
            });
          })
          .catch(err=>{
            swal(err.message)
          })
        } else {
          swal("Account deactivation canceled!");
        }
    })
  }


  const approveAccountActivation = () =>{
    swal({
      title: "Are you sure!",
      text: "You want to approve driver account activation request?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willUpdate) => {
        if (willUpdate) {
          const formData = new FormData()
          formData.append("driver_id", params.driver_id)
          formData.append("status", base64_encode("ACTIVE"))
          fetch(`${BASE_URL}update_driver_status?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
          .then(res=>res.json())
          .then(res=>{
            getApiData()
            swal(`${res.result}! ${res.message}!`, {
              icon: "success",
            });
          })
          .catch(err=>{
            swal(err.message)
          })
        } else {
          swal("Account activation canceled!");
        }
    })
  }


  const rejectAccountActivation = () =>{
    swal({
      title: "Are you sure!",
      text: "You want to reject driver account activation request?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willUpdate) => {
        if (willUpdate) {
          const formData = new FormData()
          formData.append("driver_id", params.driver_id)
          formData.append("status", base64_encode("REJECTED"))
          fetch(`${BASE_URL}update_driver_status?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
          .then(res=>res.json())
          .then(res=>{
            getApiData()
            swal(`${res.result}! ${res.message}!`, {
              icon: "success",
            });
          })
          .catch(err=>{
            swal(err.message)
          })
        } else {
          swal("Account rejection canceled!");
        }
    })
  }

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../../assets/img/brand/logo.png"),
          imgAlt: "..."
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <>{isLoading===true ? (<Indicator/>) :(
        <>{data?(
        <>
          <UserHeader full_name={base64_decode(params.driver_name)} />
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
                <Card className="card-profile shadow">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={`${data.user_profile.photo}`}
                            // src={require("../../assets/img/icons/common/user.png")}
                          />
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  </CardHeader>
                  <CardBody className="pt-0 pt-md-4">
                    <Row>
                      <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                          {/* <div>
                            <span className="heading">22</span>
                            <span className="description">Friends</span>
                          </div>
                          <div>
                            <span className="heading">10</span>
                            <span className="description">Photos</span>
                          </div>
                          <div>
                            <span className="heading">89</span>
                            <span className="description">Comments</span>
                          </div> */}
                        </div>
                      </div>
                    </Row>
                    <div className="text-center">
                      <h3>
                        {/* {user.username} */}
                        {/* <span className="font-weight-light">, 27</span> */}
                      </h3>
                      <div className="h5 font-weight-300">
                        <i className="ni location_pin mr-2" />
                        Address: {data.driver_profile.address}
                      </div>
                      <div className="h5 mt-4">
                        <i className="ni business_briefcase-24 mr-2" />
                        {data.driver_profile.email}
                      </div>
                      <div>
                        <i className="ni education_hat mr-2" />
                        {data.driver_profile.first_name} {data.driver_profile.last_name}
                      </div>
                      <hr className="my-4" />
                      <p>Status</p>
                      <span className="alert-success p-1">{data.driver_status.status}</span>
                      <br/>
                    </div>
                    {(()=>{
                      if (data.driver_status.status==="REQUESTED") {
                        return(
                          <Row>
                            <Col lg="6">
                              <div className="text-center p-2">
                                <Button onClick={()=>approveAccountActivation()} color="primary">APPROVE REQUEST</Button>
                              </div>
                            </Col>

                            <Col lg="6">
                              <div className="text-center p-2">
                                <Button onClick={()=>rejectAccountActivation()} color="danger">REJECT REQUEST</Button>
                              </div>
                            </Col>
                          </Row>
                        )
                      }else if (data.driver_status.status==="ACTIVE") {
                        return(
                          <div className="text-center p-2">
                            <Button onClick={()=>deactivateDriverAccount()} color="warning">DISABLE</Button>
                          </div>
                        )
                      }else if (data.driver_status.status==="DISABLED") {
                        return(
                          <div className="text-center p-2">
                            <Button onClick={()=>activateDriverAccount()} color="primary">ACTIVATE</Button>
                          </div>
                        )
                      }
                    })()}
                    
                  </CardBody>
                </Card>
              </Col>
              <Col className="order-xl-1" xl="8">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Driver account</h3>
                      </Col>
                      {localStorage.getItem("username")==="sandc_technology"&&(
                      <Col className="text-right" xs="4">
                        <Button
                          color="primary"
                          // href="#pablo"
                          onClick={(e) => {
                            e.preventDefault()
                            history.push(`/system-admins-list`)
                          }}
                        >
                          System Admins
                        </Button>
                      </Col>
                      )}
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        User information
                      </h6>
                      <div className="pl-lg-">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                First name
                              </label>
                              <Input
                                className="form-control"
                                id="input-username"
                                placeholder="First name"
                                defaultValue={data.driver_profile.first_name}
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Last name
                              </label>
                              <Input
                                className="form-control"
                                id="input-username"
                                placeholder="Last name"
                                defaultValue={data.driver_profile.last_name}
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Email
                              </label>
                              <Input
                                className="form-control"
                                id="input-username"
                                placeholder="Email"
                                defaultValue={data.driver_profile.email}
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Phone Number
                              </label>
                              <Input
                                className="form-control"
                                id="input-username"
                                placeholder="Phone Number"
                                defaultValue={data.driver_profile.phone_number}
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                City
                              </label>
                              <Input
                                className="form-control"
                                id="input-username"
                                placeholder="City"
                                defaultValue={data.driver_profile.city}
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Town
                              </label>
                              <Input
                                className="form-control"
                                id="input-username"
                                placeholder="Town"
                                defaultValue={data.driver_profile.town}
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
    
                  </CardBody>

                </Card>
              </Col>

            </Row>
            <br/>
            <Card className="shadow">
              <CardBody>
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Address
                      </label>
                      <textarea
                        className="form-control"
                        id="input-username"
                        placeholder="address"
                        defaultValue={data.driver_profile.address}
                        type="text"
                        disabled
                      ></textarea>
                    </FormGroup>
                  </Col>

                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Details
                      </label>
                      <textarea
                        className="form-control"
                        id="input-username"
                        placeholder="Details"
                        defaultValue={data.driver_profile.details}
                        type="text"
                        disabled
                      ></textarea>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>  


            <br/>
            <Card className="shadow">
              <CardBody>
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Licence Number
                      </label>
                      <Input
                        className="form-control"
                        id="input-username"
                        placeholder="Town"
                        defaultValue={data.driver_licence.licence_number}
                        type="text"
                        disabled
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="6">
                    <Card className="p-3 shadow">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Driver Licence (Front view)
                      </label>
                      <div>
                        {/* <img src={data.driver_licence.front} alt="LICENCE" width={'100%'} height={340} /> */}
                        <div
                        style={{
                          minHeight: "340px",
                          backgroundImage: `url(${data.driver_licence.front})`,
                          backgroundSize: "contain",
                          backgroundRepeat:"no-repeat",
                          backgroundPosition: "center"
                        }}
                        ></div>
                      </div>
                    </Card>
                  </Col>

                  <Col lg="6">
                    <Card className="p-3 shadow">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Driver Licence (Front view)
                      </label>
                      <div>
                        {/* <img src={data.driver_licence.back} alt="LICENCE" width={'100%'} height={340} /> */}
                        <div
                        style={{
                          minHeight: "340px",
                          backgroundImage: `url(${data.driver_licence.back})`,
                          backgroundSize: "contain",
                          backgroundRepeat:"no-repeat",
                          backgroundPosition: "center"
                        }}
                        ></div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>



            <br/>
            <Card className="shadow">
              <CardBody>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Vehicle (Service category)
                      </label>
                      <Input
                        className="form-control"
                        id="input-username"
                        defaultValue={data.vehicle_info.category_name}
                        type="text"
                        disabled
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Plate Number
                      </label>
                      <Input
                        className="form-control"
                        id="input-username"
                        defaultValue={data.vehicle_info.plate_number}
                        type="text"
                        disabled
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Make
                      </label>
                      <Input
                        className="form-control"
                        id="input-username"
                        defaultValue={data.vehicle_info.make}
                        type="text"
                        disabled
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Model
                      </label>
                      <Input
                        className="form-control"
                        id="input-username"
                        defaultValue={data.vehicle_info.model}
                        type="text"
                        disabled
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Year
                      </label>
                      <Input
                        className="form-control"
                        id="input-username"
                        defaultValue={data.vehicle_info.year}
                        type="text"
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  

                  <Col lg="12">
                    <Card className="p-3 shadow">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Vehicle Picture
                      </label>
                      <div>
                        {/* <img src={data.vehicle_info.vehicle_photo} alt="LICENCE" width={'100%'} height={400} /> */}
                        <div
                        style={{
                          minHeight: "700px",
                          backgroundImage: `url(${data.vehicle_info.vehicle_photo})`,
                          backgroundSize: "contain",
                          backgroundRepeat:"no-repeat",
                          backgroundPosition: "center"
                        }}
                        ></div>
                      </div>
                    </Card>
                  </Col>

                  <Col lg="6">
                    <Card className="p-3 shadow">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Vehicle certificate
                      </label>
                      <div>
                        {/* <img src={data.vehicle_info.vehicle_certificate} alt="LICENCE" width={'100%'} height={340} /> */}
                        <div
                        style={{
                          minHeight: "340px",
                          backgroundImage: `url(${data.vehicle_info.vehicle_certificate})`,
                          backgroundSize: "contain",
                          backgroundRepeat:"no-repeat",
                          backgroundPosition: "center"
                        }}
                        ></div>
                      </div>
                    </Card>
                  </Col>

                  <Col lg="6">
                    <Card className="p-3 shadow">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Vehicle insurance
                      </label>
                      <div>
                        {/* <img src={data.vehicle_info.vehicle_insurance} alt="LICENCE" width={'100%'} height={340} /> */}
                        <div
                        style={{
                          minHeight: "340px",
                          backgroundImage: `url(${data.vehicle_info.vehicle_insurance})`,
                          backgroundSize: "contain",
                          backgroundRepeat:"no-repeat",
                          backgroundPosition: "center"
                        }}
                        ></div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </>
        ):(
          <Indicator/>
        )}</>
        )}</>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default ViewDriverProfile;
