import { Link } from "react-router-dom/cjs/react-router-dom.min"
import {encode as base64_encode} from 'base-64';

export default function UserProfileLink({user_id, user_name, user_photo, user_phone, user_role}) {
  return(
    <Link 
      to={
        user_role==="driver" ? 
        `/view-driver-profile/${base64_encode(user_id.toString())}/${`${base64_encode(user_name)}`}`
        :
        `/view-user-profile/${base64_encode(user_id.toString())}/${`${base64_encode(user_name)}`}`
      }
      >
      <div style={{display:"flex"}}>
        <div>
          <img src={user_photo} style={{width:"50px", height:"50px", borderRadius:"50%"}} alt="user" />
        </div>
        <div>
            <div style={{justifyContent:"center", alignItems:"center", flex:1, margin:5}}>
                <span>{user_name}</span>
                <br/>
                <span>{user_phone}</span>
            </div>
        </div>
      </div>
    </Link>
  )
}