import {
  Card,
  Table,
  Row,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal} from "reactstrap";
import PlainHeader from "components/Headers/PlainHeader";
import { useEffect, useState } from "react";
import { APP_ID } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { BASE_URL } from "Api/Api";
import Indicator from "components/Loader/Indicator";
import {encode as base64_encode} from 'base-64';
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import UserProfileLink from "components/Footers/UserProfileLink";
import { OneStar } from "components/Stars";
import { TwoStar } from "components/Stars";
import { ThreeStar } from "components/Stars";
import { FourStar } from "components/Stars";
import { FiveStar } from "components/Stars";

const CompletedRequests = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const user_id = localStorage.getItem("user_id")
  const role = localStorage.getItem("role")
  const [selectedRating, setSelectedRating] = useState("")

  if (!user_id || role!=="admin") {
      history.push("/")
  }

  const getApiData = () => {
    const url = `${BASE_URL}get_driver_requests_per_status/${base64_encode("COMPLETED")}?app_id=${APP_ID}&key=${APP_KEY}`
    fetch(url)
    .then(res=>res.json())
    .then(data=>{
        setLoading(false)
        setUsers(data.data)
        $(document).ready(function () {
            $('#myTable').DataTable();
        });
    })
  }

  useEffect(()=>{
      setLoading(true)
      getApiData();
  },[])

  document.addEventListener("keyup", (m)=>{
    if (m.key==="Escape") {
      setSelectedRating("")
    }
  })

  return (
      <>{loading===true ? (<Indicator/>):(
      <>
      {/* Page content */}
      <PlainHeader />
      <CardBody>
        {/* Table */}
          <Row>
              <div className="col">
                  <Card className="shadow">
                      {/* <CardHeader className="border-0">
                          <h3 className="mb-0">Courses list</h3>
                      </CardHeader> */}
                      <CardBody>
                          <Table id="myTable" className="align-items-center table-flush" responsive>
                              <thead className="thead-dark">
                                  <tr>
                                      <th scope="col">S/N</th>
                                      <th scope="col">CUSTOMER DETAILS</th>
                                      <th scope="col">DRIVER DETAILS</th>
                                      <th scope="col">DISTANCE</th>
                                      <th scope="col">AMOUNT</th>
                                      {/* <th scope="col">NOTE</th> */}
                                      <th scope="col">PICK</th>
                                      <th scope="col">DROP</th>
                                      <th scope="col">STATUS</th>
                                      <th scope="col">CREATED AT</th>
                                      <th scope="col" />
                                  </tr>
                              </thead>
                              <tbody>
                                {users.map((data,idx)=>
                                <tr key={idx}>
                                  <td>{++idx}</td>
                                  <td>
                                    <UserProfileLink user_id={data.customer_id} user_name={data.customer_name} user_photo={data.customer_photo} user_phone={data.customer_phone} user_role={data.customer_role} />
                                  </td>
                                  <td>
                                    {data.driver_name!=="user"&&(
                                      <UserProfileLink user_id={data.driver_id} user_name={data.driver_name} user_photo={data.driver_photo} user_phone={data.driver_phone} user_role={data.driver_role} />
                                    )}
                                  </td>
                                  <td>{data.distance < 1 ? `${Math.round(data.distance)} M` : `${Math.round(data.distance)} KM`}</td>
                                  <td>Tsh: {Intl.NumberFormat().format(data.amount)}</td>
                                  
                                  {/* <td>{data.note}</td> */}
                                  <td>{data.pick_station}</td>
                                  <td>{data.drop_station}</td>

                                  <td>
                                    {(()=>{
                                      if (data.status==="PENDING") {
                                        return(
                                          <span className="btn-danger p-1" style={{borderRadius:6}}><b>{data.status}</b></span>
                                        )
                                      }else if (data.status==="ACCEPTED") {
                                        return(
                                          <span className="btn-info p-1" style={{borderRadius:6}}><b>{data.status}</b></span>
                                        )
                                      }else if (data.status==="COMPLETED") {
                                        return(
                                          <span className="btn-warning p-1" style={{borderRadius:6}}><b>{data.status}</b></span>
                                        )
                                      }else{
                                        return(
                                          <span className="btn-danger p-1" style={{borderRadius:6}}><b>{data.status}</b></span>
                                        )
                                      }
                                    })()}
                                  </td>
                                  <td>{data.created_at}</td>
                                  <td>
                                    <UncontrolledDropdown>
                                      <DropdownToggle
                                          className="btn-icon-only text-dark"
                                          href="#pablo"
                                          role="button"
                                          size="sm"
                                          color=""
                                          onClick={(e) => e.preventDefault()}
                                        >
                                        <i className="fas fa-ellipsis-v" />
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdown-menu-arrow" right>
                                        <DropdownItem
                                        onClick={(e)=>{
                                          e.preventDefault()
                                          setSelectedRating(data.id)
                                        }}
                                        tag={Link}
                                        >
                                        View Rating
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>

                                    {selectedRating===data.id&&(
                                    <Modal
                                      isOpen={true}
                                      // onAfterOpen={afterOpenModal}
                                      // onRequestClose={closeModal}
                                      contentLabel="Ratings"
                                      >
                                      <div style={{padding:20}}>
                                        <div style={{alignItems:"flex-end", textAlign:"right"}}>
                                          <button className="btn btn-info" onClick={()=>setSelectedRating("")}>close</button>
                                        </div>

                                        <h2>Rating</h2>
                                        <hr/>
                                        <div>
                                          {data.rating.length > 0&&(
                                            <>
                                            <div>
                                              <p>{data.rating[0].customer_name}</p>
                                            </div>
                                            {(()=>{
                                              if (data.rating[0].stars===1) {
                                                return <OneStar/>
                                              }else if (data.rating[0].stars===2) {
                                                return <TwoStar/>
                                              }else if (data.rating[0].stars===3) {
                                                return <ThreeStar/>
                                              }else if (data.rating[0].stars===4) {
                                                return <FourStar/>
                                              }else if (data.rating[0].stars===5) {
                                                return <FiveStar/>
                                              }
                                            })()}
                                            <div>
                                              <p>{data.rating[0].comment}</p>
                                              <p>{data.rating[0].created_at}</p>
                                            </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </Modal>
                                    )}
                                  </td>
                                </tr>
                                )}
                              </tbody>
                          </Table>
                      </CardBody>
                  </Card>
              </div>
          </Row>
      </CardBody>
      </>
      )}</>
  );
};

export default CompletedRequests;