import { Container } from "reactstrap";

const PlainHeader = () => {

  return (
    <>
      <div className="header pb-7">
        <Container fluid>
          {/* <div className="header-body">
          </div> */}
        </Container>

      </div>
      {/* <div className="p-4">
        <Row>
          <Col lg="">
            <Link to="/admin/index">
              <i className="fas fa-home" /> Dashboard
            </Link>
          </Col>
        </Row>
      </div> */}
    </>
  );
};

export default PlainHeader;
